var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-escaner-acta","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog",staticStyle:{"width":"380px"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Escáner Acta Bono")]),_c('button',{ref:"closeModalActa",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-body rounded",class:_vm.file_pdf
                ? 'alert-default-success border border-success'
                : !_vm.file_pdf && _vm.escaner_acta
                ? ''
                : 'alert-default-warning border border-warning'},[(_vm.escaner_acta)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"small-box bg-info"},[_c('div',{staticClass:"inner"},[_c('p',[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? "Conductor:" : "Viaje:")+" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? _vm.bonoViaje.asignacion.nombres + " " + _vm.bonoViaje.asignacion.apellidos : _vm.bonoViaje.asignacion_id)+" ")]),(_vm.bonoViaje.asignacion_type == 'App\\Viaje')?_c('p',[_vm._v(" Guía: "+_vm._s(_vm.bonoViaje.asignacion.guia.numero)+" -"+_vm._s(_vm.bonoViaje.asignacion.guia.digito_verificacion)+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? "Doc Conductor:" : "Placa:")+" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? _vm.bonoViaje.asignacion.nombres + " " + _vm.bonoViaje.asignacion.apellidos : _vm.bonoViaje.asignacion.vehiculo.placa)+" ")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-trash-a",on:{"click":function($event){return _vm.destroyActa()}}})]),(
                        _vm.$store.getters.can(
                          'hidrocarburos.gestionBonosViajes.printActa'
                        )
                      )?_c('a',{staticClass:"small-box-footer",attrs:{"href":_vm.escaner_acta,"target":"_blank"}},[_vm._v("Imprimir Acta "),_vm._m(0)]):_vm._e()])])])]):_vm._e(),(
                !_vm.escaner_acta &&
                  _vm.$store.getters.can(
                    'hidrocarburos.ventanasOperaciones.saveActa'
                  )
              )?_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"link_pdf"}},[_vm._v("Subir PDF")]),_c('br'),_c('div',{staticClass:"form-group"},[_c('input',{ref:"link_pdf",class:_vm.file_pdf
                      ? 'btn btn-sm border border-success alert-success'
                      : 'btn btn-sm border border-warning alert-warning',staticStyle:{"min-width":"320px"},attrs:{"type":"file","id":"link_pdf","accept":".pdf, .PDF"},on:{"change":function($event){return _vm.elegirDirectorio()}}})]),(_vm.loading)?_c('div',{staticClass:"spinner-border text-success",attrs:{"role":"status"}}):_vm._e(),(_vm.loading)?_c('b',{staticClass:"text-success"},[_vm._v(" Cargando...")]):_vm._e(),(
                  !_vm.loading &&
                    _vm.file_pdf &&
                    _vm.$store.getters.can(
                      'hidrocarburos.gestionBonosViajes.saveActa'
                    )
                )?_c('button',{staticClass:"btn btn-lg btn-success",attrs:{"id":"guardar_documento","type":"button","data-html":"true","title":"Guardar Documento","data-toggle":"tooltip"},on:{"click":function($event){return _vm.saveFiles()}}},[_c('span',[_vm._v("Cargar")])]):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"progress mt-3"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated bg-success",class:_vm.progress < 50
                    ? 'bg-danger'
                    : _vm.progress > 50 && _vm.progress < 98
                    ? 'bg-warning'
                    : 'bg-success',style:('width:' + _vm.progress + '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progress)+"% ")])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-file-pdf text-white"})])
}]

export { render, staticRenderFns }