<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Bonos Viajes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Bonos</li>
                  <li class="breadcrumb-item active">Consulta Gestión</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-6">
                    <h5>Filtros</h5>
                  </div>
                  <div class="col-6">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-primary btn-sm"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_geston_bono_export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.gestionBonosViajes.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <!-- Id Bono -->
                  <div class="form-group col-md-1">
                    <label>Id Bono</label>
                    <input
                      type="number"
                      v-model="filtros.id"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Número Guía -->
                  <div class="form-group col-md-2">
                    <label>Guía</label>
                    <input
                      type="number"
                      v-model="filtros.numero_guia"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Número Ticket -->
                  <div class="form-group col-md-2">
                    <label>Ticket</label>
                    <input
                      type="number"
                      v-model="filtros.numero_ticket"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Vehículo -->
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.vehiculo"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Remolque -->
                  <div class="form-group col-md-2">
                    <label>Remolque</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.remolque"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Número Conductor -->
                  <div class="form-group col-md-2">
                    <label>Doc. Conductor</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Documento"
                      v-model="filtros.numero_conductor"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <!-- Empresa -->
                  <div class="form-group col-md-3">
                    <label>Empresa</label>
                    <v-select
                      label="razon_social"
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      :options="listasForms.empresas"
                      @input="selectEmpresa()"
                    ></v-select>
                  </div>
                  <!-- Sitio Cargue -->
                  <div class="form-group col-md-3">
                    <label>Sitio Cargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_cargue"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitioCargue()"
                    ></v-select>
                  </div>
                  <!-- Sitio Descargue -->
                  <div class="form-group col-md-3">
                    <label>Sitio Descargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_descargue"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitioDescargue()"
                    ></v-select>
                  </div>
                  <!-- Ruta -->
                  <div class="form-group col-md-3">
                    <label>Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      label="nombre"
                      :options="listasForms.rutas"
                      @input="selectRuta()"
                    ></v-select>
                  </div>
                  <!-- Fecha Pago -->
                  <div class="form-group col-md-2">
                    <label>Fecha Pago</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_pago"
                      @input="getIndex()"
                    />
                  </div>
                  <!-- Fecha Expedición -->
                  <div class="form-group col-md-2">
                    <div class="form-group">
                      <label for="fecha_expedicion">Fecha Expedición</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_expedicion"
                        @input="getIndex()"
                      />
                    </div>
                  </div>
                  <!-- Estado Bono Viaje -->
                  <div class="form-group col-md-2">
                    <label>Estado Bono</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados_bonos"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <!-- Estado Viaje -->
                  <div class="form-group col-md-2">
                    <label>Estado Viaje</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado_viaje"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm text-nowrap"
                    >
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th class="p-3" rowspan="2"></th>
                          <th class="p-3" rowspan="2">#</th>
                          <th class="p-3" rowspan="2">Tipo Asignación</th>
                          <th class="p-3" rowspan="2">Guía / Ticket</th>
                          <th class="p-3" rowspan="2">Expedición</th>
                          <th class="p-3" rowspan="2">Fecha Pago</th>
                          <th class="p-3" rowspan="2">Vehículo</th>
                          <th class="p-3" rowspan="2">Remolque</th>
                          <th class="p-3" rowspan="2">Conductor</th>
                          <th class="p-3" rowspan="2">Transportadora</th>
                          <th class="p-3" rowspan="2">Ruta</th>
                          <th class="p-3" rowspan="2">Sitio Cargue</th>
                          <th class="p-3" rowspan="2">Sitio Descargue</th>
                          <th class="p-3" rowspan="2">Estado Viaje</th>
                          <th class="p-3" rowspan="2">Estado Bono</th>
                          <th colspan="2">
                            Montos
                          </th>
                        </tr>
                        <tr class="text-center">
                          <th>Asignado</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="bonoV in bonosViajes.data"
                          :key="bonoV.id"
                          class="text-center"
                        >
                          <td>
                            <div
                              class="btn-group"
                              :class="
                                bonosViajes.data.length ? 'dropop' : 'dropright'
                              "
                              style="position: static; z-index: auth"
                            >
                              <button
                                type="button"
                                class="btn btn-dark btn-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fas fa-align-justify"></i>
                              </button>
                              <div class="dropdown-menu">
                                <a
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#modal-asignacion-monto"
                                  style="cursor: pointer"
                                  @click="
                                    $refs.gestionBonoAsignacionMonto.llenarModal(
                                      bonoV
                                    )
                                  "
                                  v-if="
                                    bonoV.asignacion_type == 'App\\Viaje' &&
                                      bonoV.estado == 1
                                  "
                                >
                                  <i class="fas fa-money-bill-wave"></i>
                                  Asignar Monto
                                </a>
                                <a
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#modal-escaner-acta"
                                  style="cursor: pointer"
                                  @click="
                                    $refs.gestionBonoEscaner.llenar_modal_escaner_acta(
                                      bonoV
                                    )
                                  "
                                  v-if="
                                    ($store.getters.can(
                                      'hidrocarburos.gestionBonosViajes.saveActa'
                                    ) &&
                                      bonoV.det_programacion &&
                                      bonoV.det_programacion.tipo_bono == 1) ||
                                      (!bonoV.det_programacion &&
                                        $store.getters.can(
                                          'hidrocarburos.gestionBonosViajes.saveActa'
                                        ))
                                  "
                                >
                                  <i class="fas fa-camera-retro"></i>
                                  Escaner Acta Bono
                                </a>
                                <a
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#modal-escaner-pago"
                                  style="cursor: pointer"
                                  @click="
                                    $refs.GestionBonoEscanerPago.llenar_modal(
                                      bonoV
                                    )
                                  "
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.gestionBonosViajes.saveSoportePago'
                                    ) &&
                                      bonoV.det_programacion &&
                                      bonoV.det_programacion.tipo_bono == 2
                                  "
                                >
                                  <i class="fas fa-camera-retro"></i>
                                  Escaner pago
                                </a>
                                <a
                                  class="dropdown-item"
                                  style="cursor: pointer"
                                  @click="printActa(bonoV.id, bonoV.montoTotal)"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.gestionBonosViajes.printActa'
                                    ) && bonoV.estado != 1
                                  "
                                >
                                  <i class="fas fa-print"></i>
                                  Imprimir Acta Bono
                                </a>
                              </div>
                            </div>
                          </td>
                          <!-- Id Tabla Bono Viajes -->
                          <td>{{ bonoV.id }}</td>
                          <!-- Tipo Asignación -->
                          <td>
                            <span
                              v-if="bonoV.asignacion_type == 'App\\Viaje'"
                              class="badge bg-info"
                            >
                              Viaje
                            </span>
                            <span v-else class="badge bg-primary">
                              Conductor
                            </span>
                          </td>
                          <!-- Guía / Ticket -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            <div v-if="bonoV.asignacion.guia_id != null">
                              {{ bonoV.asignacion.guia.numero }} -
                              {{ bonoV.asignacion.guia.digito_verificacion }}
                            </div>
                            <div v-if="bonoV.asignacion.ticket_id != null">
                              {{ bonoV.asignacion.ticket.numero_ticket }}
                            </div>
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Fecha Expedicion -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            <div v-if="bonoV.asignacion.guia_id != null">
                              {{
                                bonoV.asignacion.guia.det_guias[0]
                                  .fecha_expedicion
                              }}
                            </div>
                            <div v-if="bonoV.asignacion.ticket_id != null">
                              {{ bonoV.asignacion.ticket.fecha_cargue }}
                            </div>
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Fecha Pago -->
                          <td>
                            <div v-if="bonoV.fecha_pago">
                              {{ bonoV.fecha_pago }}
                              <a
                                :href="uri_docs + bonoV.link_soporte"
                                v-if="bonoV.link_soporte"
                                target="_blank"
                              >
                                <i
                                  class="fas fa-download text-success ml-2"
                                ></i>
                              </a>
                            </div>
                            <i
                              class="fas fa-times-circle text-danger"
                              v-else
                            ></i>
                          </td>
                          <!-- Vehiculo -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{ bonoV.asignacion.vehiculo.placa }}
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Remolque -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{
                              bonoV.asignacion.remolque.placa
                                ? bonoV.asignacion.remolque.placa
                                : ""
                            }}
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Conductor -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{ bonoV.asignacion.conductor.nombres }}
                            {{ bonoV.asignacion.conductor.apellidos }}
                            <span class="badge badge-info">
                              {{ bonoV.asignacion.conductor.numero_documento }}
                            </span>
                          </td>
                          <td v-else>
                            {{ bonoV.asignacion.nombres }}
                            {{ bonoV.asignacion.apellidos }}
                            <span class="badge badge-info">
                              {{ bonoV.asignacion.numero_documento }}
                            </span>
                          </td>
                          <!-- Empresa -->
                          <td>{{ bonoV.empresa.razon_social }}</td>
                          <!-- Ruta -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{
                              bonoV.asignacion.ruta
                                ? bonoV.asignacion.ruta.nombre
                                : ""
                            }}
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Sitio Cargue -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{
                              bonoV.asignacion.sitio_cargue
                                ? bonoV.asignacion.sitio_cargue.nombre
                                : ""
                            }}
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Sitio Descargue -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            {{
                              bonoV.asignacion.sitio_descargue
                                ? bonoV.asignacion.sitio_descargue.nombre
                                : ""
                            }}
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Estado Viaje -->
                          <td v-if="bonoV.asignacion_type == 'App\\Viaje'">
                            <span
                              class="badge"
                              :class="
                                bonoV.asignacion.estado == 1 ||
                                bonoV.asignacion.estado == 7
                                  ? 'badge-primary'
                                  : bonoV.asignacion.estado == 6 ||
                                    bonoV.asignacion.estado == 9
                                  ? 'bg-navy'
                                  : bonoV.asignacion.estado == 5 ||
                                    bonoV.asignacion.estado == 10
                                  ? 'badge-info'
                                  : bonoV.asignacion.estado == 4 ||
                                    bonoV.asignacion.estado == 2
                                  ? 'badge-success'
                                  : bonoV.asignacion.estado == 3
                                  ? 'badge-danger'
                                  : 'badge-dark'
                              "
                            >
                              {{ bonoV.asignacion.nEstadoViaje }}
                            </span>
                          </td>
                          <td v-else>
                            <span class="badge bg-info">Sin Asignar</span>
                          </td>
                          <!-- Estado Bono Viaje -->
                          <td>
                            <span
                              class="badge"
                              :class="
                                bonoV.estado == 1
                                  ? 'badge-warning'
                                  : bonoV.estado == 2
                                  ? 'badge-success'
                                  : 'badge-info'
                              "
                            >
                              {{ bonoV.nEstadoBonoViaje }}
                            </span>
                          </td>
                          <!-- Bonos Asignados -->
                          <td v-if="bonoV.bonos.length">
                            <span
                              class="text-center badge bg-navy"
                              v-b-popover.hover.top.html="
                                getMontosHTML(bonoV.bonos)
                              "
                              :title="'Montos'"
                              >{{ bonoV.bonos.length }} Montos</span
                            >
                          </td>
                          <td v-else>
                            <span class="badge bg-warning"> Sin Asignar</span>
                          </td>
                          <!-- Total Montos -->
                          <td v-if="bonoV.bonos.length">
                            <i class="fas fa-dollar-sign">
                              {{
                                parseInt(bonoV.montoTotal).toLocaleString()
                              }}</i
                            >
                          </td>
                          <td v-else>
                            <span class="badge bg-warning"> Sin Asignar</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="bonosViajes.total">
                  <p>
                    Mostrando del <b>{{ bonosViajes.from }}</b> al
                    <b>{{ bonosViajes.to }}</b> de un total:
                    <b>{{ bonosViajes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="bonosViajes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <GestionBonoExport ref="GestionBonoExport" />
    <GestionBonoEscaner ref="gestionBonoEscaner" />
    <GestionBonoAsignacionMonto ref="gestionBonoAsignacionMonto" />
    <GestionBonoEscanerPago ref="GestionBonoEscanerPago" />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { VBPopover } from "bootstrap-vue";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import GestionBonoEscaner from "./GestionBonoEscaner";
import GestionBonoExport from "./GestionBonoExport.vue";
import GestionBonoAsignacionMonto from "./GestionBonoAsignacionMonto.vue";
import GestionBonoEscanerPago from "./GestionBonoEscanerPago";
export default {
  name: "GestionBonoIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
    vSelect,
    GestionBonoExport,
    GestionBonoEscaner,
    GestionBonoAsignacionMonto,
    GestionBonoEscanerPago,
  },

  data() {
    return {
      cargando: false,
      bonosViajes: {},
      filtros: {
        id: null,
        numero_guia: null,
        numero_ticket: null,
        vehiculo: null,
        remolque: null,
        numero_conductor: null,
        estado_viaje: null,
        empresa_id: null,
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        ruta_id: null,
        estado: null,
        fecha_pago: null,
        fecha_expedicion: null,
      },
      bono: null,
      empresa: {},
      ruta: {},
      sitio_cargue: {},
      sitio_descargue: {},
      listasForms: {
        rutas: [],
        sitios: [],
        estados: [],
        empresas: [],
        estados_bonos: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      formDoc: {},
      formRecBono: { sitio_id: null },
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    formDoc: {
      fecha_pago: {
        required,
      },
    },
    formMasivo: {
      fecha_pago: { required },
    },
    formRecBono: {
      fecha_inicio: { required },
      fecha_fin: { required },
      tipo_ruta: { required },
      sitio_id: { required },
    },
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/bonoViaje?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.cargando = false;
          this.bonosViajes = response.data;
          this.bonosViajes.data.forEach((bonoV) => {
            if (bonoV.bonos.length) {
              bonoV.montoTotal = bonoV.bonos.reduce((sum, bono) => {
                return sum + parseInt(bono.nMonto, 10);
              }, 0);
            }
          });
        });
    },

    limpiar() {
      this.filtros = {
        id: null,
        numero_guia: null,
        numero_ticket: null,
        vehiculo: null,
        remolque: null,
        numero_conductor: null,
        estado_viaje: null,
        empresa_id: null,
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        ruta_id: null,
        estado: null,
        fecha_pago: null,
        fecha_expedicion: null,
      };
      this.empresa = {};
      this.ruta = {};
      this.sitio_cargue = {};
      this.sitio_descargue = {};
      this.getIndex();
    },

    async buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      await axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEstadosBonosViajes() {
      await axios.get("/api/lista/221").then((response) => {
        this.listasForms.estados_bonos = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validarFecha() {
      if (this.formRecBono.fecha_inicio && this.formRecBono.fecha_fin) {
        const fecha_ini = new Date(this.formRecBono.fecha_inicio);
        const fecha_fin = new Date(this.formRecBono.fecha_fin);
        if (fecha_ini > fecha_fin) {
          this.formRecBono.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicial no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
      this.getIndex();
    },

    selectRuta() {
      this.filtros.ruta_id = null;
      if (this.ruta) {
        this.filtros.ruta_id = this.ruta.id;
      }
      this.getIndex();
    },

    selectSitioCargue() {
      this.filtros.sitio_cargue_id = null;
      if (this.sitio_cargue) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      }
      this.getIndex();
    },

    selectSitioDescargue() {
      this.filtros.sitio_descargue_id = null;
      if (this.sitio_descargue) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      }
      this.getIndex();
    },

    printActa(bono_viaje_id, montoTotal) {
      this.cargando = true;
      let data = {
        montoTotal: montoTotal,
        bono_viaje_id: bono_viaje_id,
      };
      axios
        .get(`/api/hidrocarburos/bonoViaje/printActa`, {
          params: data,
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getMontosHTML(bonos) {
      if (bonos.length) {
        return bonos
          .map(
            (b, index) =>
              `<p>${index + 1}) $${parseInt(b.nMonto).toLocaleString()}</p>`
          )
          .join("");
      } else {
        return "<p>No hay montos asignadas.</p>";
      }
    },
  },

  async beforeMount() {
    await this.getEstadosBonosViajes();
    await this.getEstados();
    await this.buscarTransportadora();
    await this.buscarRutas();
    await this.buscarSitios();
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
