<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-asignacion-monto">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignación Monto</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="card border border-ligth shadow"
              :class="cargando_div ? 'overlay-wrapper' : ''"
            >
              <div v-if="cargando_div" class="overlay">
                <i class="fas fa-5x fa-sync-alt fa-spin"></i>
                <div class="text-lg pt-2">Cargando...</div>
              </div>
              <div class="card-body text-muted text-xs">
                <div
                  class="col-md-12"
                  v-if="bonoViaje.asignacion_type == 'App\\Viaje'"
                >
                  <div class="row">
                    <!-- Viaje -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-box"></i
                          >{{ " " + bonoViaje.asignacion_id }}
                        </p>
                        <span class="description-text text-secondary"
                          >Viaje:</span
                        >
                      </div>
                    </div>
                    <!-- Empresa -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-building">
                            {{ bonoViaje.empresa.razon_social }}
                          </i>
                        </p>
                        <span class="description-text text-secondary"
                          >Empresa:</span
                        >
                      </div>
                    </div>
                    <!-- Vehículo -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-shipping-fast">
                            {{ bonoViaje.asignacion.vehiculo.placa }}
                          </i>
                        </p>
                        <span class="description-text text-secondary"
                          >Vehículo:</span
                        >
                      </div>
                    </div>
                    <!-- Remolque -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-truck-loading">
                            {{
                              bonoViaje.asignacion.remolque
                                ? bonoViaje.asignacion.remolque.placa
                                : ""
                            }}
                          </i>
                        </p>
                        <span class="description-text text-secondary"
                          >Remolque:</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2"></div>
                    <!-- Conductor -->
                    <div class="col-sm-4">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-user"></i>
                          {{ bonoViaje.asignacion.conductor.nombres }}
                          {{ bonoViaje.asignacion.conductor.apellidos }}
                        </p>
                        <span class="description-text text-secondary"
                          >Conductor:</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-1"></div>
                    <!-- Doc Conductor -->
                    <div class="col-sm-4">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-phone-alt">
                            {{
                              bonoViaje.asignacion.conductor.numero_documento
                            }}
                          </i>
                        </p>
                        <span class="description-text text-secondary"
                          >Doc Conductor:</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Sitio Cargue -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-box"></i
                          >{{ bonoViaje.asignacion.sitio_cargue.nombre }}
                        </p>
                        <span class="description-text text-secondary"
                          >Sitio Cargue:</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-2"></div>
                    <!-- Sitio Descargue -->
                    <div class="col-sm-4">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-building">
                            {{ bonoViaje.asignacion.sitio_descargue.nombre }}
                          </i>
                        </p>
                        <span class="description-text text-secondary"
                          >Sitio Descargue:</span
                        >
                      </div>
                    </div>
                    <!-- Guia / Ticket -->
                    <div class="col-sm-3">
                      <div class="description-block">
                        <p class="description-header">
                          <i class="fas fa-file">
                            {{
                              bonoViaje.asignacion.guia_id
                                ? bonoViaje.asignacion.guia.numero +
                                  "-" +
                                  bonoViaje.asignacion.guia.digito_verificacion
                                : bonoViaje.asignacion.ticket_id
                                ? bonoViaje.asignacion.ticket.numero_ticket
                                : ""
                            }}
                          </i>
                        </p>
                        <span class="description-text text-secondary">{{
                          bonoViaje.asignacion.guia_id ? "Guia:" : "Ticket:"
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 mt-2">
              <label>Sitio</label>
              <v-select
                v-model="form.sitio_id"
                placeholder="Sitio"
                label="nombre"
                class="border rounded shadow-sm p-0"
                :class="
                  $v.form.sitio_id.$invalid ? 'border-danger' : 'border-success'
                "
                :options="listasForms.sitios"
                @search="buscarSitios"
                @input="buscarBonos"
                :reduce="(sitio) => sitio.id"
              ></v-select>
            </div>
            <div class="form-group col-md-12" v-if="listasForms.bonos.length">
              <label for="origen">Bonos</label>
              <v-select
                v-model="form.bonos"
                placeholder="bonos"
                label="codMonto"
                class="border rounded shadow-sm p-0"
                :options="listasForms.bonos"
                multiple
                :class="
                  $v.form.bonos.$invalid ? 'border-danger' : 'border-success'
                "
                :reduce="(bono) => bono.id"
                @input="sumaBonos"
              ></v-select>
            </div>
            <div
              v-if="!listasForms.bonos.length && form.sitio_id"
              class="text-danger text-center"
            >
              <div class="form-group col-md-12">
                No se encontraron bonos disponibles para este sitio.
              </div>
            </div>
            <div
              class="row justify-content-center"
              v-if="listasForms.bonos.length"
            >
              <div class="col-md-3">
                <div class="info-box">
                  <span class="info-box-icon bg-info elevation-1"
                    ><i class="fas fa-coins"></i
                  ></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Monto Programado</span>
                    <span class="info-box-number">
                      {{
                        "$ " +
                          parseFloat(
                            bonoViaje.det_programacion.nMonto
                          ).toLocaleString("es-ES")
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <i
                  class="fas fa-minus fa-lg"
                  style="margin-left: 35px; margin-top: 30px;"
                ></i>
              </div>
              <div class="col-md-3">
                <div class="info-box mb-3">
                  <span class="info-box-icon bg-primary elevation-1">
                    <i class="fas fa-wallet fa-sm"></i
                  ></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Monto Seleccionado</span>
                    <span class="info-box-number">{{
                      "$ " + parseFloat(montoTotal).toLocaleString("es-ES")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <i
                  class="fas fa-equals fa-lg"
                  style="margin-left: 35px; margin-top: 30px;"
                ></i>
              </div>
              <div class="col-md-3">
                <div class="info-box mb-3">
                  <span
                    class="info-box-icon elevation-1"
                    :class="
                      bonoViaje.det_programacion.nMonto &&
                      montoTotal &&
                      parseFloat(bonoViaje.det_programacion.nMonto) -
                        parseFloat(montoTotal) <
                        0
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                    ><i class="fas fa-shopping-cart"></i
                  ></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Total</span>
                    <span class="info-box-number">{{
                      "$ " +
                        (
                          parseFloat(bonoViaje.det_programacion.nMonto) -
                          parseFloat(montoTotal)
                        ).toLocaleString("es-ES")
                    }}</span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                !$v.form.$invalid &&
                  bonoViaje.det_programacion.nMonto &&
                  montoTotal &&
                  parseFloat(bonoViaje.det_programacion.nMonto) -
                    parseFloat(montoTotal) >=
                    0
              "
              @click="saveAsignacion()"
            >
              Asignar Montos
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "GestionBonoAsignacionMonto",
  components: {
    vSelect,
  },
  data() {
    return {
      montoTotal: 0,
      bonoViaje: {},
      cargando_div: false,
      form: {
        id: null,
        asignacion_type: null,
        asignacion_id: null,
        sitio_id: null,
        bonos: [],
      },
      listasForms: {
        sitios: [],
        bonos: [],
      },
    };
  },
  validations: {
    form: {
      asignacion_id: {
        required,
      },
      sitio_id: {
        required,
      },
      bonos: {
        required,
      },
    },
  },
  methods: {
    llenarModal(bonoViaje) {
      this.limpiarForm();
      this.bonoViaje = bonoViaje;
      this.form = {
        id: bonoViaje.id,
        asignacion_type: bonoViaje.asignacion_type,
        asignacion_id: bonoViaje.asignacion_id,
        sitio_id: null,
        bonos: [],
      };
    },

    limpiarForm() {
      this.form = {
        id: null,
        asignacion_type: null,
        asignacion_id: null,
        sitio_id: null,
        bonos: [],
      };
      this.montoTotal = 0;
      this.listasForms.bonos = [];
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let self = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            self.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(error) {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },

    buscarBonos() {
      let self = this;
      self.form.bonos = [];
      self.listasForms.bonos = [];
      if (self.form.sitio_id) {
        self.cargando_div = true;
        var url = "api/hidrocarburos/bonos/lista";
        axios
          .get(url, {
            params: {
              sitio_id: self.form.sitio_id,
            },
          })
          .then(function(response) {
            self.listasForms.bonos = response.data;
            self.cargando_div = false;
          })
          .catch(function(error) {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            self.cargando_div = false;
          });
      }
      self.montoTotal = self.listasForms.bonos.length ? self.montoTotal : 0;
    },

    sumaBonos() {
      this.montoTotal = 0;
      let bonoEncontrado = [];
      this.form.bonos.forEach((bono) => {
        bonoEncontrado = this.listasForms.bonos.find(
          (element) => element.id === bono
        );
        if (bonoEncontrado) {
          this.montoTotal += parseInt(bonoEncontrado.nMonto);
        }
      });

      if (
        parseFloat(this.bonoViaje.det_programacion.nMonto) -
          parseFloat(this.montoTotal) <
        0
      ) {
        this.montoTotal = this.montoTotal - parseInt(bonoEncontrado.nMonto);
        this.form.bonos.pop();
        this.$swal({
          icon: "error",
          title:
            "Se excedio el monto total, por favor validar, lo programado es " +
            "$ " +
            parseFloat(this.bonoViaje.det_programacion.nMonto).toLocaleString(
              "es-ES"
            ),
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    saveAsignacion() {
      let self = this;
      self.$parent.cargando = true;
      if (!self.$v.form.$invalid) {
        let data = {
          form: self.form,
          montoTotal: self.montoTotal,
        };
        axios({
          method: "POST",
          url: "/api/hidrocarburos/bonoViaje/saveAsignacion",
          data: data,
        })
          .then((response) => {
            let data = response.data;
            self.$parent.cargando = false;
            self.limpiarForm();
            self.$refs.closeModal.click();
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            self.$parent.cargando = false;
            self.$swal({
              icon: "error",
              title: "Ocurrio un error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
};
</script>
