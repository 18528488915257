<template>
  <div>
    <!-- Modal Adjunto Foto-->
    <div
      class="modal fade"
      id="modal-escaner-acta"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="width: 380px">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Escáner Acta Bono</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalActa"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="card-body rounded"
              :class="
                file_pdf
                  ? 'alert-default-success border border-success'
                  : !file_pdf && escaner_acta
                  ? ''
                  : 'alert-default-warning border border-warning'
              "
            >
              <div v-if="escaner_acta">
                <div class="row">
                  <div class="col-md-12">
                    <!-- small box -->
                    <div class="small-box bg-info">
                      <div class="inner">
                        <p>
                          {{
                            bonoViaje.asignacion_type == "App\\Conductor"
                              ? "Conductor:"
                              : "Viaje:"
                          }}
                          {{
                            bonoViaje.asignacion_type == "App\\Conductor"
                              ? bonoViaje.asignacion.nombres +
                                " " +
                                bonoViaje.asignacion.apellidos
                              : bonoViaje.asignacion_id
                          }}
                        </p>
                        <p v-if="bonoViaje.asignacion_type == 'App\\Viaje'">
                          Guía: {{ bonoViaje.asignacion.guia.numero }} -{{
                            bonoViaje.asignacion.guia.digito_verificacion
                          }}
                        </p>
                        <p>
                          {{
                            bonoViaje.asignacion_type == "App\\Conductor"
                              ? "Doc Conductor:"
                              : "Placa:"
                          }}
                          {{
                            bonoViaje.asignacion_type == "App\\Conductor"
                              ? bonoViaje.asignacion.nombres +
                                " " +
                                bonoViaje.asignacion.apellidos
                              : bonoViaje.asignacion.vehiculo.placa
                          }}
                        </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-trash-a" @click="destroyActa()"></i>
                      </div>
                      <a
                        class="small-box-footer"
                        :href="escaner_acta"
                        target="_blank"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.gestionBonosViajes.printActa'
                          )
                        "
                        >Imprimir Acta
                        <button type="button" class="btn btn-sm bg-danger">
                          <i class="fas fa-file-pdf text-white"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  !escaner_acta &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.saveActa'
                    )
                "
              >
                <label for="link_pdf">Subir PDF</label>
                <br />
                <div class="form-group">
                  <input
                    type="file"
                    id="link_pdf"
                    ref="link_pdf"
                    style="min-width: 320px;"
                    :class="
                      file_pdf
                        ? 'btn btn-sm border border-success alert-success'
                        : 'btn btn-sm border border-warning alert-warning'
                    "
                    accept=".pdf, .PDF"
                    @change="elegirDirectorio()"
                  />
                </div>
                <div
                  v-if="loading"
                  class="spinner-border text-success"
                  role="status"
                ></div>
                <b v-if="loading" class="text-success"> Cargando...</b>
                <button
                  v-if="
                    !loading &&
                      file_pdf &&
                      $store.getters.can(
                        'hidrocarburos.gestionBonosViajes.saveActa'
                      )
                  "
                  id="guardar_documento"
                  type="button"
                  class="btn btn-lg btn-success"
                  data-html="true"
                  title="Guardar Documento"
                  data-toggle="tooltip"
                  @click="saveFiles()"
                >
                  <span>Cargar</span>
                </button>
              </div>
              <div class="progress mt-3" v-if="loading">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  :class="
                    progress < 50
                      ? 'bg-danger'
                      : progress > 50 && progress < 98
                      ? 'bg-warning'
                      : 'bg-success'
                  "
                  role="progressbar"
                  :style="'width:' + progress + '%'"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "GestionBonoEscaner",
  components: {},
  data() {
    return {
      bonoViaje: {},
      cargar: null,
      progress: null,
      loading: false,
      imagenCargada: null,
      escaner_acta: null,
      file_pdf: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    llenar_modal_escaner_acta(bonoV) {
      this.limpiarModal();
      this.bonoViaje = bonoV;
      this.escaner_acta = bonoV.link_soporte
        ? this.uri_docs + bonoV.link_soporte
        : null;
    },

    saveFiles() {
      var self = this;
      self.loading = true;
      let formData = new FormData();
      if (self.file_pdf) {
        formData.append("link_pdf", self.file_pdf);
        formData.append("bono_viaje_id", this.bonoViaje.id);
        formData.append(
          "conductor_id",
          this.bonoViaje.asignacion_type == "App\\Viaje"
            ? this.bonoViaje.asignacion.conductor_id
            : this.bonoViaje.asignacion_id
        );
        axios
          .post("api/hidrocarburos/bonoViaje/saveActa", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              self.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          })
          .then((response) => {
            self.progress == 100
              ? (self.loading = false)
              : (self.loading = true);
            this.$parent.getIndex();
            this.$refs.closeModalActa.click();
            self.$swal({
              icon: "success",
              title:
                "El Acta " + self.file_pdf.name + " se cargó correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            self.file_pdf = null;
            self.$refs.link_pdf.files = null;
            let inputPdf = document.getElementById("link_pdf");
            inputPdf.value = "";
          })
          .catch(function(error) {
            self.loading = false;
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        self.loading = false;
        self.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    destroyActa() {
      if (
        this.$store.getters.can("hidrocarburos.gestionBonosViajes.deleteActa")
      ) {
        let data = {
          id: this.bonoViaje.id,
          link: this.bonoViaje.link_soporte,
        };
        this.$swal({
          title: "Esta seguro de eliminar este Archivo?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
        }).then((result) => {
          if (result.value) {
            this.$parent.cargando = true;
            axios
              .post("/api/hidrocarburos/bonoViaje/descartarActa", data)
              .then(() => {
                this.$parent.getIndex();
                this.limpiarModal();
                this.$swal({
                  icon: "success",
                  title: "El Archivo se elimino correctamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch(function(error) {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error" + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.$parent.cargando = false;
              });
          }
        });
      }
    },

    elegirDirectorio() {
      this.file_pdf = this.$refs.link_pdf.files[0];
    },

    limpiarModal() {
      this.file_pdf = null;
      this.escaner_acta = null;
    },
  },
};
</script>
